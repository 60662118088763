<template>
  <div class="statistics-div__container">
    <div v-if="!checkLoading && !showErrPage">
      <div class="statistics-div__title">
        <div class="statistics-title__left">{{$t('taskhub.statistics.title')}}</div>
        <div class="statistics-title__right">
          <span
            v-for="item in publicList"
            :key="item.value"
            :class="['public-btn', { active: item.value === true }]"
            @click="switchTime(item)"
            >{{ item.label }}</span
          >
        </div>
      </div>
      <Loading :loading="loading" v-if="loading" />
      <div v-show="!loading">
        <div class="statistics-div__count">
          <div class="statistics-count__number">
            <span class="content">
              <p>
                <img src="@/assets/img/svg/statistics/statis-all.svg" alt="">
                {{$t('taskhub.statistics.all')}}
              </p>
              <p>{{ total.toLocaleString() }}</p>
            </span>
          </div>
          <div class="statistics-count__analysis">
            <span class="content">
            <p>
              <img src="@/assets/img/svg/statistics/statis-done.svg" alt="">
              {{$t('taskhub.statistics.done')}}
            </p>
            <p>{{ completed.toLocaleString() }}</p>
            </span>
          </div>
          <div class="statistics-count__analysis">
            <span class="content">
            <p>
              <img src="@/assets/img/svg/statistics/statis-running.svg" alt="">
              {{$t('taskhub.statistics.running')}}
            </p>
            <p>{{ running.toLocaleString() }}</p>
            </span>
          </div>
          <div class="statistics-count__wait">
            <span class="content">
            <p>
              <img src="@/assets/img/svg/statistics/statis-waiting.svg" alt="">
              {{$t('taskhub.statistics.waiting')}}
            </p>
            <p>{{ pending.toLocaleString() }}</p>
            </span>
          </div>
          <div class="statistics-count__wait">
            <span class="content">
            <p>
              <img src="@/assets/img/svg/statistics/statis-failed.svg" alt="">
              {{$t('taskhub.statistics.failed')}}
            </p>
            <p>{{ failed.toLocaleString() }}</p>
            </span>
          </div>
        </div>
        <div class="statistics-div__top">
          <div v-if="typeResult.length" ref="typePie"></div>
          <div v-else>
            <p class="title">{{$t('taskhub.statistics.type')}}</p>
            <no-data style="margin-top:120px;" type="chart"></no-data>
          </div>
          <div v-if="analysisresult.length" ref="resultPie"></div>
          <div v-else>
            <p class="title">{{$t('taskhub.statistics.result')}}</p>
            <no-data style="margin-top:120px;" type="chart"></no-data>
          </div>
        </div>
        <div class="statistics-div__top">
          <div v-if="fileType.length" ref="fileType"></div>
          <div v-else>
            <p class="title">{{$t('taskhub.statistics.file_type')}}</p>
            <no-data style="margin-top:120px;" type="chart"></no-data>
          </div>
          <div v-if="maliceFile.length" ref="maliceFile"></div>
          <div v-else>
            <p class="title">{{$t('taskhub.statistics.malice')}}</p>
            <no-data style="margin-top:120px;" type="chart"></no-data>
          </div>
        </div>
        <div class="statistics-div__top">
          <div v-if="behaviorCharacteristics.length" ref="behaviorCharacteristics" class="height300"></div>
          <div v-else class="height300">
            <p class="title">{{$t('taskhub.statistics.behavior')}}</p>
            <no-data style="margin-top:120px;" type="chart"></no-data>
          </div>
          <div v-if="analysisEnvironment.length" ref="analysisEnvironment" class="height300"></div>
          <div v-else class="height300">
            <p class="title">{{$t('taskhub.statistics.environment')}}</p>
            <no-data style="margin-top:120px;" type="chart"></no-data>
          </div>
        </div>
        <div class="statistics-div-main">
          <div v-if="submittrend.length" ref="summitLine"></div>
          <div v-else>
            <p class="title">{{$t('taskhub.statistics.trend')}}</p>
            <no-data style="margin-top:120px;" type="chart"></no-data>
          </div>
          <div v-if="threattrend.length" ref="outputLine"></div>
          <div v-else>
            <p class="title">{{$t('taskhub.statistics.threat')}}</p>
            <no-data style="margin-top:120px;" type="chart"></no-data>
          </div>
        </div>
        <div class="statistics-div__bottom">
          <div v-if="getTags.length" ref="getTags"></div>
          <div v-else>
            <p class="title">{{$t('taskhub.statistics.tag')}}</p>
            <no-data style="margin-top:60px;" type="chart"></no-data>
          </div>
        </div>
        <!-- <div v-if="maliceFmaliy.length" class="statistics-div__bottom">
          <div ref="maliceFmaliy">
            <p class="title">恶意家族排行</p>
            <no-data style="margin-top:120px;" type="chart"></no-data>
          </div>
        </div>
        <div v-if="apt.length" class="statistics-div__bottom">
          <div ref="apt">
            <p class="title">命中威胁组织排行</p>
            <no-data style="margin-top:120px;" type="chart"></no-data>
          </div>
        </div> -->
        <div v-if="cve.length" class="statistics-div__bottom">
          <div ref="cve"></div>
        </div>
        <div v-if="exploit.length" class="statistics-div__bottom">
          <div ref="exploit"></div>
        </div>
      </div>
    </div>
    <Loading :loading="checkLoading" v-if="checkLoading" />
    <div class="close-captch__wrap" v-if="showErrPage">
      <close-captch :showFooter="false"></close-captch>
    </div>
  </div>
  <backTo-top isContainer=".router-view__wrap"></backTo-top>
</template>

<script>
import {
  getPersonalviewStatus,
  getPersonalviewType,
  getPersonalviewAnalysisresult,
  getPersonalviewThreattrend,
  getPersonalviewTags,
  getPersonalviewSubmittrend,
  getPersonalviewStatis,
  getPersonalviewTopten
} from 'services/statistics'
import { checkLogin } from 'services/auth'

import closeCaptch from '@/components/common/closeCaptch.vue'

import * as echarts from 'echarts'
import * as formatters from 'utils/lib/formatters'
import emitter from '@/app/emitter.js'
import { markRaw } from 'vue'
import { getColor, watchSwitchTheme } from 'app/style'

import { t, isZh } from 'app/i18n'
const Map = {
  count_not_threat: {
    name: t('report.overview.chart.is_threaten.secure'),
    color: '#51c21a'
  },
  count_may_threat: {
    name: t('report.overview.chart.is_threaten.suspicious'),
    color: '#f6b313'
  },
  count_threat: {
    name: t('report.overview.chart.is_threaten.malice'),
    color: '#cf3e4a'
  }
}

const otherColorList = [
  '#2fc49a',
  '#5b8ff9',
  '#5d7092',
  '#f6bd16',
  '#e86452',
  '#6dc8ec',
  '#945fb9',
  '#ff9845',
  '#1e9493',
  '#ff99c3'
]

const TypeMap = {
  file: {
    name: isZh() ? '文件' : 'file',
    color: '#5b8ff9'
  },
  url: {
    name: 'url',
    color: '#2fc49a'
  },
  md5: {
    name: 'md5',
    color: '#FFDB71'
  }
}
export default {
  name: 'Statistics',
  components: {
    closeCaptch
  },
  data() {
    return {
      loading: true,
      showErrPage: false,
      checkLoading: true,
      publicList: [
        {
          label: t('taskhub.statistics.day'),
          value: false,
          day: true
        },
        {
          label: t('taskhub.statistics.week'),
          value: true
        }
      ],
      queryTime: {
        query: '7d'
      },
      pending: 0,
      running: 0,
      completed: 0,
      failed: 0,
      total: 0,
      typeResult: {},
      analysisresult: {},
      threattrend: [],
      submittrend: [],
      getTags: [],
      noData: t('nodata'),
      fileType: [],
      fileTypeChrat: {},
      maliceFile: [],
      maliceFileChart: {},
      analysisEnvironmentChart: {},
      analysisEnvironment: [],
      behaviorCharacteristics: [],
      behaviorCharacteristicsChart: {},
      maliceFmaliy: [],
      maliceFmaliyChart: {},
      exploit: [],
      exploitChart: {},
      apt: [],
      aptChart: {},
      cve: [],
      cveChart: {}
    }
  },
  mounted() {
    this.getLoginStatus()
    window.addEventListener('resize', this.chartsResize)
    watchSwitchTheme(() => {
      if (this.$refs.typePie) this.initTypePie()
      if (this.$refs.resultPie) this.initResultPie()
      if (this.$refs.summitLine) this.initSubmitLine()
      if (this.$refs.getTags) this.initGetTags()
      if (this.$refs.outputLine) this.initOutputLine()
      if (this.$refs.fileType) this.initFileType()
      if (this.$refs.maliceFile) this.initMaliceFile()
      if (this.$refs.behaviorCharacteristics) this.initBehaviorCharacteristics()
      if (this.$refs.analysisEnvironment) this.initAnalysisEnvironment()
      if (this.$refs.cve) this.initCveChart()
      if (this.$refs.exploit) this.initExploitChart()
    })
    emitter.on('reloadResult', () => {
      this.showErrPage = false
      this.getAllData()
    })
  },
  methods: {
    switchTime(item) {
      const label = item.label
      const arr = JSON.parse(JSON.stringify(this.publicList))
      arr.forEach((e) => {
        if (e.label === label) {
          e.value = true
        } else {
          e.value = false
        }
      })
      this.publicList = arr
      if (!item.day) {
        this.queryTime.query = '7d'
      } else {
        this.queryTime.query = '1d'
      }
    },
    initTypePie() {
      this.typePieChart = echarts.init(this.$refs.typePie)
      const arr = []
      const count = this.typeResult.find(v => v.key === 'md5')?.doc_count || 0
      if (this.typeResult.filter(v => v.key !== 'md5').length) {
        this.typeResult.filter(v => v.key !== 'md5').forEach((item) => {
          const o = {
            itemStyle: {}
          }
          o.name = TypeMap[item.key].name
          o.value = item.doc_count
          if (item.key === 'file') o.value += count
          o.itemStyle.color = TypeMap[item.key].color
          arr.push(o)
        })
      } else {
        arr.push({
          name: TypeMap.file.name,
          value: count,
          itemStyle: {
            color: TypeMap.file.color
          }
        })
      }
      const opt = {
        title: {
          show: true,
          text: t('taskhub.statistics.type'),
          textStyle: {
            fontSize: 14,
            color: getColor('--color-text-2')
          },
          top: 12,
          left: 20
        },
        tooltip: {
          trigger: 'item',
          // textStyle: {
          //   fontSize: 12,
          //   color: '#fff'
          // },
          // borderWidth: 0,
          // backgroundColor: '#31364ab3',
          // boxShadow: '0 2px 6px 0 #10121b',
          formatter: function(item) {
            const texts = [
              `${item.name}：`,
              `${item.value.toLocaleString()}`,
              `<br/>${item.percent}%`
            ]
            return texts.join('')
          }
        },
        series: [
          {
            name: t('taskhub.statistics.type'),
            type: 'pie',
            radius: ['40%', '50%'],
            top: 10,
            emphasis: {
              label: {
                show: true,
                fontWeight: 'bold'
              }
            },
            label: {
              fontSize: 14,
              formatter: '{b} {c}\n{d}%',
              color: getColor('--color-text-2'),
              show: true
            },
            labelLine: {
              show: true,
              lineStyle: {
                color: getColor('--color-text-2')
              }
            },
            data: arr
          }
        ]
      }
      this.typePieChart.setOption(opt)
    },
    initResultPie() {
      this.resultPieChart = echarts.init(this.$refs.resultPie)
      let arr = []
      arr = this.analysisresult.filter((item) => !!item.count)
      arr.forEach((item) => {
        item.itemStyle = {}
        item.name = Map[item.key].name
        item.value = item.count
        item.itemStyle.color = Map[item.key].color
      })
      arr.sort()
      const mid = arr.pop()
      arr.unshift(mid)
      const opt = {
        title: {
          show: true,
          text: t('taskhub.statistics.result'),
          textStyle: {
            fontSize: 14,
            color: getColor('--color-text-2')
          },
          top: 12,
          left: 20
        },
        tooltip: {
          trigger: 'item',
          formatter: function(item) {
            const texts = [
              `${item.name}：`,
              `${item.value.toLocaleString()}`,
              `<br/>${item.percent}%`
            ]
            return texts.join('')
          }
        },
        series: [
          {
            name: t('taskhub.statistics.result'),
            type: 'pie',
            radius: ['40%', '50%'],
            top: 10,
            emphasis: {
              label: {
                show: true,
                // fontSize: '20',
                fontWeight: 'bold'
              }
            },
            label: {
              fontSize: 14,
              formatter: '{b} \n{c} ({d}%)',
              color: getColor('--color-text-3'),
              show: true
            },
            labelLine: {
              show: true,
              lineStyle: {
                color: getColor('--color-text-2')
              }
            },
            data: arr
          }
        ]
      }
      this.resultPieChart.setOption(opt)
    },
    initSubmitLine() {
      this.submitLine = echarts.init(this.$refs.summitLine)
      const xAxisData = this.submittrend.map((item) => item.key_as_string)
      const data = this.submittrend.map((item) => item.doc_count)
      const option = {
        title: {
          show: true,
          text: t('taskhub.statistics.trend'),
          textStyle: {
            fontSize: 14,
            color: getColor('--color-text-2')
          },
          top: 12,
          left: 20
        },
        tooltip: {
          trigger: 'axis',
          formatter: function(item) {
            const texts = [t('taskhub.statistics.trend_tip'), `${item[0].value.toLocaleString()}`, '<br />', t('taskhub.statistics.trend_date_tip'), item[0].axisValue.replace(' 00:00:00', '')]
            return texts.join('')
          }
        },
        grid: {
          bottom: 30,
          left: '60',
          right: '50',
          top: 100
        },
        xAxis: {
          name: t('taskhub.statistics.trend_xAxis'),
          nameTextStyle: {
            color: getColor('--color-text-3')
          },
          type: 'category',
          boundaryGap: false,
          data: xAxisData,
          axisLabel: {
            show: true,
            color: getColor('--color-text-2'),
            formatter: (value, index) => {
              const date = new Date(value.replace(/-/g, '/'))
              if (this.queryTime.query === '7d') {
                var texts = [date.getMonth() + 1, date.getDate()]
                if (index === 0) {
                  texts.unshift(date.getFullYear())
                }
                return texts.join('/')
              } else {
                let min = '0'
                if (!date.getMinutes()) {
                  min = '00'
                } else {
                  min = date.getMinutes()
                }
                const texts = [date.getHours(), min]
                return texts.join(':')
              }
            }
          }
        },
        yAxis: {
          name: t('taskhub.statistics.trend_yAxis'),
          nameGap: 30,
          nameTextStyle: {
            color: getColor('--color-text-3'),
            padding: [0, 0, 0, -20]
          },
          type: 'value',
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            show: true,
            color: getColor('--color-text-2'),
            formatter: function(value, index) {
              return formatters.formatNumberByUnit(value)
            }
          },
          splitLine: {
            lineStyle: {
              color: getColor('--color-text-4'),
              type: 'dashed'
            }
          }
        },
        series: [
          {
            data: data,
            type: 'line',
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(0,171,122,1)'
                },
                {
                  offset: 1,
                  color: 'rgba(0,171,122,0.1)'
                }
              ])
            },
            lineStyle: {
              color: '#00ab7a'
            },
            smooth: false,
            showSymbol: false
          }
        ]
      }
      this.submitLine.setOption(option)
    },
    initOutputLine() {
      this.outputLine = echarts.init(this.$refs.outputLine)
      const xAxisData = this.threattrend.map((item) => item.key_as_string)
      const data = this.threattrend.map((item) => item.doc_count)
      const option = {
        title: {
          show: true,
          text: t('taskhub.statistics.threat'),
          textStyle: {
            fontSize: 14,
            color: getColor('--color-text-2')
          },
          top: 12,
          left: 20
        },
        tooltip: {
          trigger: 'axis',
          formatter: function(item) {
            const texts = [t('taskhub.statistics.trend_tip'), `${item[0].value.toLocaleString()}`, '<br />', t('taskhub.statistics.trend_date_tip'), item[0].axisValue.replace(' 00:00:00', '')]
            return texts.join('')
          }
        },
        grid: {
          bottom: 30,
          left: '60',
          right: '50',
          top: 100
        },
        xAxis: {
          name: t('taskhub.statistics.trend_xAxis'),
          nameTextStyle: {
            color: getColor('--color-text-3')
          },
          type: 'category',
          boundaryGap: false,
          data: xAxisData,
          axisLabel: {
            show: true,
            color: getColor('--color-text-2'),
            formatter: (value, index) => {
              const date = new Date(value.replace(/-/g, '/'))
              if (this.queryTime.query === '7d') {
                var texts = [date.getMonth() + 1, date.getDate()]
                if (index === 0) {
                  texts.unshift(date.getFullYear())
                }
                return texts.join('/')
              } else {
                let min = '0'
                if (!date.getMinutes()) {
                  min = '00'
                } else {
                  min = date.getMinutes()
                }
                const texts = [date.getHours(), min]
                return texts.join(':')
              }
            }
          }
        },
        yAxis: {
          name: t('taskhub.statistics.trend_yAxis'),
          nameTextStyle: {
            color: getColor('--color-text-3'),
            padding: [0, 0, 0, -20]
          },
          nameGap: 30,
          type: 'value',
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            show: true,
            color: getColor('--color-text-2'),
            formatter: function(value, index) {
              return formatters.formatNumberByUnit(value)
            }
          },
          splitLine: {
            lineStyle: {
              color: getColor('--color-text-4'),
              type: 'dashed'
            }
          }
        },
        series: [
          {
            data: data,
            type: 'line',
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(0,171,122,1)'
                },
                {
                  offset: 1,
                  color: 'rgba(0,171,122,0.1)'
                }
              ])
            },
            lineStyle: {
              color: '#00ab7a'
            },
            smooth: false,
            showSymbol: false
          }
        ]
      }
      this.outputLine.setOption(option)
    },
    initGetTags() {
      this.histogram = echarts.init(this.$refs.getTags)
      const xAxisData = []
      const data = []
      this.getTags.forEach((item) => {
        if (isZh()) {
          xAxisData.push(item.key)
        } else {
          xAxisData.push(item.enus)
        }
        data.push(item.doc_count)
      })
      const histogramOpt = {
        title: {
          show: true,
          text: t('taskhub.statistics.tag'),
          textStyle: {
            fontSize: 14,
            color: getColor('--color-text-2')
          },
          top: 12,
          left: 20,
          itemGap: 50
        },
        grid: {
          top: 90,
          left: 60,
          right: 80,
          bottom: 50
        },
        xAxis: {
          name: t('taskhub.statistics.tag_xAxis'),
          nameTextStyle: {
            color: getColor('--color-text-3')
          },
          type: 'category',
          data: xAxisData,
          axisLine: {
            show: false
          },
          axisLabel: {
            show: true,
            color: getColor('--color-text-2')
            // rotate: 30
          }
        },
        yAxis: {
          type: 'value',
          splitLine: {
            lineStyle: {
              color: getColor('--color-text-4'),
              type: 'dashed'
            }
          },
          nameTextStyle: {
            color: getColor('--color-text-3'),
            padding: [0, 0, 10, -25]
          },
          name: t('taskhub.statistics.tag_yAxis'),
          axisLine: {
            show: false
          },
          axisLabel: {
            show: true,
            color: getColor('--color-text-2')
          }
        },
        tooltip: {
          trigger: 'item',
          formatter: function(item) {
            const texts = [`${item.name}：`, `${item.value.toLocaleString()}`]
            return texts.join('')
          }
        },
        series: [
          {
            data: data,
            type: 'bar',
            barWidth: 10,
            backgroundStyle: {
              color: 'rgba(220, 220, 220, 0.8)'
            },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#00ab7a'
                },
                {
                  offset: 1,
                  color: '#00ab7a'
                }
              ])
            }
          }
        ]
      }
      this.histogram.setOption(histogramOpt)
    },
    initFileType() {
      this.fileTypeChrat = echarts.init(this.$refs.fileType)
      const arr = []
      this.fileType.forEach((item) => {
        const o = {
          itemStyle: {}
        }
        o.name = item.key
        o.value = item.doc_count
        arr.push(o)
      })
      const opt = {
        title: {
          show: true,
          text: t('taskhub.statistics.file_type'),
          textStyle: {
            fontSize: 14,
            color: getColor('--color-text-2')
          },
          top: 12,
          left: 20
        },
        color: otherColorList,
        tooltip: {
          trigger: 'item',
          formatter: function(item) {
            const texts = [
              `${item.name}：`,
              `${item.value.toLocaleString()}`,
              `<br/>${item.percent}%`
            ]
            return texts.join('')
          }
        },
        series: [
          {
            name: t('taskhub.statistics.file_type'),
            type: 'pie',
            radius: ['40%', '50%'],
            center: ['50%', '50%'],
            top: 10,
            emphasis: {
              label: {
                show: true,
                fontWeight: 'bold'
              }
            },
            minShowLabelAngle: 1,
            label: {
              fontSize: 14,
              formatter: '{b} {c}（{d}%）',
              color: getColor('--color-text-2'),
              show: true
            },
            labelLine: {
              show: true,
              lineStyle: {
                color: getColor('--color-text-2')
              }
            },
            data: arr
          }
        ]
      }
      this.fileTypeChrat.setOption(opt)
    },
    initMaliceFile() {
      this.maliceFileChart = echarts.init(this.$refs.maliceFile)
      const arr = []
      this.maliceFile.sort((a, b) => {
        return a.doc_count - b.doc_count
      }).slice(-5).forEach((item) => {
        const o = {
          itemStyle: {}
        }
        o.name = item.key
        o.value = item.doc_count
        arr.push(o)
      })
      const opt = {
        title: {
          show: true,
          text: t('taskhub.statistics.malice'),
          textStyle: {
            fontSize: 14,
            color: getColor('--color-text-2')
          },
          top: 12,
          left: 20
        },
        color: otherColorList,
        tooltip: {
          trigger: 'item',
          formatter: function(item) {
            const texts = [
              `${item.name}：`,
              `${item.value.toLocaleString()}`,
              `<br/>${item.percent}%`
            ]
            return texts.join('')
          }
        },
        series: [
          {
            name: t('taskhub.statistics.malice'),
            type: 'pie',
            radius: ['40%', '50%'],
            center: ['50%', '50%'],
            top: 10,
            emphasis: {
              label: {
                show: true,
                // fontSize: '20',
                fontWeight: 'bold'
              }
            },
            label: {
              fontSize: 14,
              formatter: '{b} {c}（{d}%）',
              color: getColor('--color-text-2'),
              show: true
            },
            labelLine: {
              show: true,
              lineStyle: {
                color: getColor('--color-text-2')
              }
            },
            data: arr
          }
        ]
      }
      this.maliceFileChart.setOption(opt)
    },
    initBehaviorCharacteristics() {
      this.behaviorCharacteristicsChart = echarts.init(this.$refs.behaviorCharacteristics)
      const indicator = []
      const data = []
      const counts = this.behaviorCharacteristics.map(v => v.doc_count)
      const max = Math.max(...counts)
      this.behaviorCharacteristics.forEach((item) => {
        indicator.push({
          text: item.key,
          max
        })
        data.push(item.doc_count)
      })
      const opt = {
        tooltip: {
          trigger: 'item'
        },
        title: {
          text: t('taskhub.statistics.behavior'),
          show: true,
          textStyle: {
            fontSize: 14,
            color: getColor('--color-text-2')
          },
          top: 12,
          left: 20
        },
        radar: [
          {
            indicator: indicator,
            center: ['50%', '50%'],
            radius: 80,
            startAngle: 90,
            splitNumber: 4,
            shape: 'circle',
            axisName: {
              color: getColor('--color-text-3')
            },
            splitArea: {
              areaStyle: {
                color: '#00ab7a80',
                opacity: 0.1
              },
              show: true
            },
            axisLine: {
              lineStyle: {
                color: '#00ab7a80',
                opacity: 0.2
              }
            },
            splitLine: {
              lineStyle: {
                color: '#00ab7a80'
              }
            }
          }
        ],
        series: [
          {
            name: t('taskhub.statistics.behavior'),
            type: 'radar',
            emphasis: {
              lineStyle: {
                width: 4
              }
            },
            areaStyle: {
              color: 'rgba(0,171,122,0.3)'
            },
            itemStyle: {
              color: '#00ab7a'
            },
            lineStyle: {
              color: '#00ab7a'
            },
            data: [
              {
                value: data
              }
            ]
          }
        ]
      }
      this.behaviorCharacteristicsChart.setOption(opt)
    },
    initAnalysisEnvironment() {
      this.analysisEnvironmentChart = echarts.init(this.$refs.analysisEnvironment)
      const arr = []
      this.analysisEnvironment.forEach((item) => {
        const o = {
          itemStyle: {}
        }
        o.name = item.key
        o.value = item.doc_count
        arr.push(o)
      })
      const opt = {
        title: {
          show: true,
          text: t('taskhub.statistics.environment'),
          textStyle: {
            fontSize: 14,
            color: getColor('--color-text-2')
          },
          top: 12,
          left: 20
        },
        color: otherColorList,
        tooltip: {
          trigger: 'item',
          formatter: function(item) {
            const texts = [
              `${item.name}：`,
              `${item.value.toLocaleString()}`,
              `<br/>${item.percent}%`
            ]
            return texts.join('')
          }
        },
        series: [
          {
            name: t('taskhub.statistics.environment'),
            type: 'pie',
            radius: ['40%', '50%'],
            center: ['50%', '50%'],
            top: 10,
            emphasis: {
              label: {
                show: true,
                // fontSize: '20',
                fontWeight: 'bold'
              }
            },
            label: {
              fontSize: 14,
              formatter: '{b} {c}（{d}%）',
              color: getColor('--color-text-2'),
              show: true
            },
            labelLine: {
              show: true,
              lineStyle: {
                color: getColor('--color-text-2')
              }
            },
            data: arr
          }
        ]
      }
      this.analysisEnvironmentChart.setOption(opt)
    },
    // maliceFmaliyChart
    initMaliceFmaliyChart() {
      this.maliceFmaliyChart = echarts.init(this.$refs.maliceFmaliy)
      const xAxisData = []
      const data = []
      this.maliceFmaliy.forEach((item) => {
        xAxisData.push(item.key)
        data.push(item.doc_count)
      })
      const opt = {
        title: {
          show: true,
          text: t('other.views.taskHub.familytop10'),
          textStyle: {
            fontSize: 14,
            color: getColor('--color-text-2')
          },
          top: 12,
          left: 20,
          itemGap: 50
        },
        grid: {
          top: 90,
          left: 60,
          bottom: 80,
          right: 80
        },
        xAxis: {
          name: t('other.views.taskHub.category'),
          type: 'category',
          data: xAxisData,
          axisLine: {
            show: false
          },
          axisLabel: {
            show: true,
            color: getColor('--color-text-2')
            // rotate: 30
          }
        },
        yAxis: {
          type: 'value',
          splitLine: {
            lineStyle: {
              color: getColor('--color-text-4'),
              type: 'dashed'
            }
          },
          nameTextStyle: {
            color: getColor('--color-text-2'),
            align: 'right'
          },
          name: t('other.views.taskHub.number'),
          axisLine: {
            show: false
          },
          axisLabel: {
            show: true,
            color: getColor('--color-text-2')
          }
        },
        tooltip: {
          trigger: 'item',
          formatter: function(item) {
            const texts = [`${item.name}：`, `${item.value.toLocaleString()}`]
            return texts.join('')
          }
        },
        series: [
          {
            data: data,
            type: 'bar',
            barWidth: 10,
            backgroundStyle: {
              color: 'rgba(220, 220, 220, 0.8)'
            },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#00ab7a'
                },
                {
                  offset: 1,
                  color: '#00ab7a'
                }
              ])
            }
          }
        ]
      }
      this.maliceFmaliyChart.setOption(opt)
    },
    // exploitChart
    initExploitChart() {
      this.exploitChart = markRaw(echarts.init(this.$refs.exploit))
      const xAxisData = []
      const data = []
      this.exploit.sort((a, b) => {
        return b.count - a.count
      }).slice(0, 10).forEach((item) => {
        xAxisData.push(item.tag_zhcn)
        data.push(item.count)
      })
      const opt = {
        title: {
          show: true,
          text: t('taskhub.statistics.exploit'),
          textStyle: {
            fontSize: 14,
            color: getColor('--color-text-2')
          },
          top: 12,
          left: 20,
          itemGap: 50
        },
        grid: {
          top: 90,
          left: 60,
          right: 80,
          bottom: 50
        },
        xAxis: {
          name: t('taskhub.statistics.tag_xAxis'),
          nameTextStyle: {
            color: getColor('--color-text-3')
          },
          type: 'category',
          data: xAxisData,
          axisLine: {
            show: false
          },
          axisLabel: {
            show: true,
            color: getColor('--color-text-2')
            // rotate: 30
          }
        },
        yAxis: {
          type: 'value',
          splitLine: {
            lineStyle: {
              color: getColor('--color-text-4'),
              type: 'dashed'
            }
          },
          nameTextStyle: {
            color: getColor('--color-text-3'),
            padding: [0, 0, 10, -25]
          },
          name: t('taskhub.statistics.tag_yAxis'),
          axisLine: {
            show: false
          },
          axisLabel: {
            show: true,
            color: getColor('--color-text-2')
          }
        },
        tooltip: {
          trigger: 'item',
          formatter: function(item) {
            const texts = [`${item.name}：`, `${item.value.toLocaleString()}`]
            return texts.join('')
          }
        },
        series: [
          {
            data: data,
            type: 'bar',
            barWidth: 10,
            backgroundStyle: {
              color: 'rgba(220, 220, 220, 0.8)'
            },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#00ab7a'
                },
                {
                  offset: 1,
                  color: '#00ab7a'
                }
              ])
            }
          }
        ]
      }
      this.exploitChart.setOption(opt)
    },
    // aptChart
    initAptChart() {
      this.aptChart = echarts.init(this.$refs.apt)
      const xAxisData = []
      const data = []
      this.apt.forEach((item) => {
        xAxisData.push(item.key)
        data.push(item.doc_count)
      })
      const opt = {
        title: {
          show: true,
          text: t('other.views.taskHub.orgtop10'),
          textStyle: {
            fontSize: 14,
            color: getColor('--color-text-2')
          },
          top: 12,
          left: 20,
          itemGap: 50
        },
        grid: {
          top: 90,
          left: 60,
          right: 80,
          bottom: 80
        },
        xAxis: {
          type: 'category',
          data: xAxisData,
          axisLine: {
            show: false
          },
          axisLabel: {
            show: true,
            color: getColor('--color-text-2')
            // rotate: 30
          }
        },
        yAxis: {
          type: 'value',
          splitLine: {
            lineStyle: {
              color: getColor('--color-text-4'),
              type: 'dashed'
            }
          },
          nameTextStyle: {
            color: getColor('--color-text-2'),
            align: 'right'
          },
          name: t('other.views.taskHub.number'),
          axisLine: {
            show: false
          },
          axisLabel: {
            show: true,
            color: getColor('--color-text-2')
          }
        },
        tooltip: {
          trigger: 'item',
          formatter: function(item) {
            const texts = [`${item.name}：`, `${item.value.toLocaleString()}`]
            return texts.join('')
          }
        },
        series: [
          {
            data: data,
            type: 'bar',
            barWidth: 10,
            backgroundStyle: {
              color: 'rgba(220, 220, 220, 0.8)'
            },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#00ab7a'
                },
                {
                  offset: 1,
                  color: '#00ab7a'
                }
              ])
            }
          }
        ]
      }
      this.aptChart.setOption(opt)
    },
    // cveChart
    initCveChart() {
      this.cveChart = markRaw(echarts.init(this.$refs.cve))
      const xAxisData = []
      const data = []
      this.cve.forEach((item) => {
        xAxisData.push(item.tag_zhcn)
        data.push(item.count)
      })
      const opt = {
        title: {
          show: true,
          text: t('taskhub.statistics.cve'),
          textStyle: {
            fontSize: 14,
            color: getColor('--color-text-2')
          },
          top: 12,
          left: 20,
          itemGap: 50
        },
        grid: {
          top: 90,
          left: 60,
          right: 80,
          bottom: 80
        },
        xAxis: {
          name: t('taskhub.statistics.tag_xAxis'),
          type: 'category',
          data: xAxisData,
          axisLine: {
            show: false
          },
          axisLabel: {
            show: true,
            color: getColor('--color-text-2')
            // rotate: 30
          }
        },
        yAxis: {
          type: 'value',
          splitLine: {
            lineStyle: {
              color: getColor('--color-text-4'),
              type: 'dashed'
            }
          },
          nameTextStyle: {
            color: getColor('--color-text-2'),
            align: 'right'
          },
          name: t('taskhub.statistics.tag_yAxis'),
          axisLine: {
            show: false
          },
          axisLabel: {
            show: true,
            color: getColor('--color-text-2')
          }
        },
        tooltip: {
          trigger: 'item',
          formatter: function(item) {
            const texts = [`${item.name}：`, `${item.value.toLocaleString()}`]
            return texts.join('')
          }
        },
        series: [
          {
            data: data,
            type: 'bar',
            barWidth: 10,
            backgroundStyle: {
              color: 'rgba(220, 220, 220, 0.8)'
            },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#00ab7a'
                },
                {
                  offset: 1,
                  color: '#00ab7a'
                }
              ])
            }
          }
        ]
      }
      this.cveChart.setOption(opt)
    },
    async getAllData() {
      this.loading = true
      // 任务统计-任务状态统计
      const res = await getPersonalviewStatus(this.queryTime)
      this.loading = false
      const { pending, running, completed, failed, total } = res
      Object.assign(this, { pending, running, completed, failed, total })
      // 任务统计-任务类型占比
      getPersonalviewType(this.queryTime).then((res) => {
        if (!res.code) {
          this.typeResult = res
          if (res.length) {
            this.$nextTick(() => {
              this.initTypePie()
            })
          }
        }
      })
      // 任务统计-裁定结果占比
      getPersonalviewAnalysisresult(this.queryTime).then((res) => {
        if (!res.code) {
          this.analysisresult = res
          if (res.length) {
            this.$nextTick(() => {
              this.initResultPie()
            })
          }
        }
      })
      // 任务统计-任务提交趋势
      getPersonalviewSubmittrend(this.queryTime).then((res) => {
        if (!res.code) {
          this.submittrend = res
          if (res.length) {
            this.$nextTick(() => {
              this.initSubmitLine()
            })
          }
        }
      })
      // 任务统计-命中标签
      getPersonalviewTags(this.queryTime).then((res) => {
        if (!res.code) {
        // if (res.length > 10) res.length = 10
          this.getTags = res.sort((a, b) => {
            return b.doc_count - a.doc_count
          }).slice(0, 10)
          if (res.length) {
            this.$nextTick(() => {
              this.initGetTags()
            })
          }
        }
      })
      // 任务统计-恶意检出任务趋势
      getPersonalviewThreattrend(this.queryTime).then((res) => {
        if (!res.code) {
          this.threattrend = res
          if (res.length) {
            this.$nextTick(() => {
              this.initOutputLine()
            })
          }
        }
      })
      // 文件类型统计
      getPersonalviewStatis({
        query: this.queryTime.query,
        statistical_type: 'file_type'
      }).then((res) => {
        if (!res.code) {
          this.fileType = res.sort((a, b) => {
            return a.doc_count - b.doc_count
          }).slice(-5)
          if (res.length) {
            this.$nextTick(() => {
              this.initFileType()
            })
          }
        }
      })
      // param.statistical_type
      // 恶意文件类型统计  malicious_file_type
      getPersonalviewStatis({
        query: this.queryTime.query,
        statistical_type: 'malicious_file_type'
      }).then((res) => {
        if (!res.code) {
          this.maliceFile = res
          if (res.length) {
            this.$nextTick(() => {
              this.initMaliceFile()
            })
          }
        }
      })
      // 行为特征分布统计 categories
      getPersonalviewStatis({
        query: this.queryTime.query,
        statistical_type: 'categories'
      }).then((res) => {
        if (!res.code) {
          this.behaviorCharacteristics = res
          if (res.length) {
            this.$nextTick(() => {
              this.initBehaviorCharacteristics()
            })
          }
        }
      })
      // 分析环境统计 expect_os_env
      getPersonalviewStatis({
        query: this.queryTime.query,
        statistical_type: 'expect_os_env'
      }).then((res) => {
        if (!res.code) {
          this.analysisEnvironment = res
          if (res.length) {
            this.$nextTick(() => {
              this.initAnalysisEnvironment()
            })
          }
        }
      })

      // 已知漏洞 top10
      getPersonalviewTopten({
        day_range: this.queryTime.query === '1d' ? 1 : 7,
        tag_type: 'cve'
      }).then((res) => {
        if (!res.code) {
          this.cve = res
          if (res.length) {
            this.$nextTick(() => {
              this.initCveChart()
            })
          }
        }
      })
      // 漏洞利用top10
      getPersonalviewTopten({
        day_range: this.queryTime.query === '1d' ? 1 : 7,
        tag_type: 'exploit'
      }).then((res) => {
        if (!res.code) {
          this.exploit = res
          if (res.length) {
            this.$nextTick(() => {
              this.initExploitChart()
            })
          }
        }
      })
      // // 恶意软件  top10
      // getPersonalviewTopten({
      //   query: this.queryTime.query,
      //   type: 'malware'
      // }).then((res) => {
      //   if (!res.code && res.length) {
      //     this.maliceFmaliy = res
      //     this.$nextTick(() => {
      //       this.initMaliceFmaliyChart()
      //     })
      //   }
      // })
      // //  威胁组织 top10
      // getPersonalviewTopten({
      //   query: this.queryTime.query,
      //   type: 'apt'
      // }).then((res) => {
      //   if (!res.code && res.length) {
      //     this.apt = res
      //     this.$nextTick(() => {
      //       this.initAptChart()
      //     })
      //   }
      // })
    },
    isEmpty(data) {
      return Object.keys(data).length === 0
    },
    chartsResize() {
      if (this.typePieChart && this.typePieChart.resize) this.typePieChart.resize()
      if (this.resultPieChart && this.resultPieChart.resize) this.resultPieChart.resize()
      if (this.submitLine && this.submitLine.resize) this.submitLine.resize()
      if (this.outputLine && this.outputLine.resize) this.outputLine.resize()
      if (this.histogram && this.histogram.resize) this.histogram.resize()

      if (!this.isEmpty(this.fileTypeChrat) && this.fileTypeChrat.resize) this.fileTypeChrat.resize()
      if (this.maliceFileChart && this.maliceFileChart.resize) this.maliceFileChart.resize()
      if (this.behaviorCharacteristicsChart && this.behaviorCharacteristicsChart.resize) this.behaviorCharacteristicsChart.resize()
      if (this.analysisEnvironmentChart && this.analysisEnvironmentChart.resize) this.analysisEnvironmentChart.resize()
      if (!this.isEmpty(this.maliceFmaliyChart) && this.maliceFmaliyChart.resize) this.maliceFmaliyChart.resize()
      if (!this.isEmpty(this.exploitChart) && this.exploitChart.resize) this.exploitChart.resize()
      if (!this.isEmpty(this.aptChart) && this.aptChart.resize) this.aptChart.resize()
      if (!this.isEmpty(this.cveChart) && this.cveChart.resize) this.cveChart.resize()
    },
    getLoginStatus() {
      checkLogin().then((res) => {
        this.checkLoading = false
        if (!res.is_login) {
          this.showErrPage = true
        } else {
          this.showErrPage = false
          this.getAllData()
        }
      })
    }
  },
  watch: {
    publicList: {
      handler() {
        this.getLoginStatus()
      },
      deep: true
    }
  },
  unmounted() {
    window.removeEventListener('resize', this.chartsResize)
    emitter.off('reloadResult')
  }
}
</script>

<style lang="scss" scoped>
.statistics-div__container {
  width: 100%;
  min-height:calc(100vh - 56px - 60px);
  overflow-y: auto;
  color: var( --color-unkown-text);

  .statistics-div__title {
    width: 100%;
    padding: 9px 24px;
    font-size: 18px;
    color: var(--color-text-1);
    box-sizing: border-box;
    font-weight: 500;
    float: left;

    .statistics-title__left {
      float: left;
      height: 100%;
      line-height: 32px;
      // margin-top: 8px;
    }

    .statistics-title__right {
      float: right;

      .public-btn {
        display: inline-block;
        box-sizing: border-box;
        width: 88px;
        height: 32px;
        line-height: 32px;
        font-size: 14px;
        border: 1px solid var(--color-text-3);
        border-radius: 2px 0px 0px 2px;
        cursor: pointer;
        text-align: center;
        color: var(--color-text-2);
        position: relative;
        &.active {
          border: 1px solid var(--color-primary);
          color: var(--color-primary);
          z-index: 2;
        }

        &:first-child {
          border-radius: 2px 0 0 2px;
        }

        &:last-child {
          left: -1px;
          border-radius: 0 2px 2px 0;
        }
      }
    }
  }

  .statistics-div__count, .statistics-div__top, .statistics-div-main, .statistics-div__bottom {
    width: calc(100% - 48px);
    display: flex;
    box-sizing: border-box;
    margin: 16px 24px;

    div {
      flex: 1;
    }
  }

  .statistics-div__top, .statistics-div-main, .statistics-div__bottom {
    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      background: var(--detect-config-bg);
      box-shadow: 0 0 4px 0 var(--popover-box-shadow1);
      border-radius: 2px;

      .title {
        position: absolute;
        top: 0;
        left: 26px;
        font-weight: 500;
        font-size: 14px;
        color: var(--color-text-2);
      }
    }
  }

  .statistics-div__count {
    background: var(--detect-config-bg);
    border-radius: 2px;
    div {
      height: 88px;
      position: relative;
      &:after {
        content: '';
        display: block;
        width: 1px;
        height: 32px;
        background: var(--color-border-2);
        position: absolute;
        top: 30px;
      }
      .content {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: fit-content;
        margin: 0 auto;
        p {
          margin: 16px 0 8px 20px;
          color: var(--color-text-3);
          text-align: center;
          img {
            margin-right: 8px;
          }
          &:first-child {
            width: 100%;
            margin-left: 0;
            line-height: 12px;
            font-size: 12px;
            color: #00ab7a;
            display: flex;
            align-items: center;
            justify-content: left;
          }
          &:last-child {
            width: 100%;
            text-align: left;
            margin: 0 0 0 0px;
            font-family: DIN Alternate;
            font-size: 28px;
            font-weight: 500;
            color: var(--color-text-2);
          }
        }
      }

      &:last-child {
        margin: 0;
      }
    }
    .statistics-count__number {
      &:after {
        display: none;
      }
    }
  }

  .statistics-div-main {
    div {
      height: 348px !important;
      padding-bottom: 8px;
    }
  }
  .statistics-div__top, .statistics-div-main {
    .height300 {
      height: 300px;
    }
    div {
      height: 240px;

      &:last-child {
        margin-left: 17px;
      }
    }
  }

  .statistics-div__bottom {
    height: 322px;
    padding-bottom: 8px;
  }
  .close-captch__wrap {
    height: calc(100vh - 56px - 60px);
  }
}
</style>
